import React from "react";
import '../App.css';
import Banner from "../Components/Banner";
import Header from "../Components/Header";
import Sym from "../images/sym.png";
import Part1 from "../images/part1.png";
import Part2 from "../images/part2.png";
import Part3 from "../images/part3.png";
import Part4 from "../images/part4.png";
import Part5 from "../images/part5.png";
import Part6 from "../images/part6.png";
import Part8 from "../images/part8.png";
import Part9 from "../images/part9.png";
import Part10 from "../images/part10.png";


class Partenaires extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "part_title":"Nos partenaires éditeurs",
            "integrateur_title":"Nos partenaires intégrateurs",

        }
    }
    state = {  }
    render() { 
        return ( 
            <div className="main-header">
                <Header/>
                 <div className="bread_section">
                    <Banner/>
                    <div className="partenaire_section">
                        <h3>{this.state.part_title}</h3>
                        <div className="integrate_section">
                            <img src={Sym} alt="log" width="190" height="auto" />
                        </div>

                        <h3>{this.state.integrateur_title}</h3>

                        <div className="integrate_logos">
                            <img src={Part4} alt="log" width="190" height="auto" />
                            <img src={Part6} alt="log" width="190" height="auto" />
                            <img src={Part8} alt="log" width="190" height="auto" />
                            <img src={Part10} alt="log" width="190" height="auto" />
                        </div>

                        <div className="integrate_logos">
                            <img src={Part2} alt="log" width="190" height="auto" />
                            <img src={Part3} alt="log" width="190" height="auto" />
                            <img src={Part5} alt="log" width="190" height="auto" />
                            <img src={Part9} alt="log" width="190" height="auto" />
                        </div>

                        <div className="integrate_logos">
                            <img src={Part1} alt="log" width="190" height="auto" />
                           
                        </div>

                    </div>
                 </div>
            </div>
         );
    }
}
 
export default Partenaires;