import React from "react";
import '../App.css';
import Banner from "../Components/Banner";
import Header from "../Components/Header";
import { useTranslation } from 'react-i18next';


function Reflexions () {
    const { t, i18n } = useTranslation();
        return ( 
            <div className="main-header">
                <Header/>
                 <div className="bread_section">
                    <Banner/>

                    <div className="refle_desc">
                       <div className="p_desc">
                          <p>{t('Reflexions.1')}</p>
                       </div>

                       <div className="sec_desc">
                          <h3>{t('Reflexions.2')}</h3>
                            <div className="collapse_desc">
                                <span>{t('Reflexions.3')}</span>
                                <p>{t('Reflexions.4')}</p>
                            </div>

                            <div className="collapse_desc">
                                <span>{t('Reflexions.5')}</span>
                                <p>{t('Reflexions.6')}</p>
                                <p>{t('Reflexions.7')}</p>
                            </div>

                            <div className="collapse_desc">
                                <span>{t('Reflexions.8')}</span>
                                <p>{t('Reflexions.9')}</p>
                            </div>
                       </div>

                       <div className='headline-h'></div>

                       <div className="sec_desc-sms">
                          <h3>{t('Reflexions.10')}</h3>
                          <div className="collapse_desc">
                                <p>{t('Reflexions.11')}</p>
                                <p>{t('Reflexions.12')}</p>
                                <p>{t('Reflexions.13')}</p>
                                <p>{t('Reflexions.14')}</p>
                                <p>{t('Reflexions.15')}</p>

                            </div>
                       </div>

                       <div className='headline-h'></div>

                       <div className="sec_desc-vis">
                          <h3>{t('Reflexions.16')}</h3>
                          <div className="collapse_desc">
                                <span>{t('Reflexions.17')}</span>
                                <p>{t('Reflexions.18')}</p>
                            </div>

                            <div className="collapse_desc">
                                <span>{t('Reflexions.19')}</span>
                                <p>{t('Reflexions.20')}</p>
                            </div>
                       </div>







                    </div>
                 </div>
            </div>
         );
    
}
 
export default Reflexions;