import React from "react";
import '../App.css';
import Slide from '../images/slide.jpeg';
import { useTranslation } from 'react-i18next';




function Banner () {
  const { t, i18n } = useTranslation();


        return ( 
        <div className='slide_section'>
            <img src={Slide} 
            className='bread_img'
            style={{  
            height: 'auto',
            backgroundPosition: 'top',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
          }}
            />
                <div className='bread_desc'>
                      <h3>{t('Services.1')}
                      </h3>
                </div>
        </div>
         );
    
}
 
export default Banner;