import React, {Component} from "react";
import log from '../images/log.png';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';



function Header() {
   const { t, i18n } = useTranslation();


   function handClick(lang){
    i18n.changeLanguage(lang);
   }
        return ( 
            <div className="header">
               <div className="logo">
                <NavLink to='/'>
                    <img src={log} alt="log" width="280" height="auto"/>
                </NavLink>
                 <div className="local-lang">
                        <span onClick={()=>handClick('fr')}> Francais</span>
                        <span onClick={()=>handClick('en')}> English</span>
                        <span> Espace client</span>
                 </div>
               </div>
                        <ul className="menu-items">
                            <NavLink to="/" >{t('Menu.1')}</NavLink>
                            <NavLink to="/reflexions">{t('Menu.2')}</NavLink>
                            <NavLink to="/experiences">{t('Menu.3')}</NavLink>
                            <NavLink to="/certifications">{t('Menu.4')}</NavLink>
                            <NavLink to="/partenaires">{t('Menu.5')}</NavLink>
                            <NavLink to="/contact">{t('Menu.6')}</NavLink>
                          
                        </ul>
            </div>
         );
    
}
export default Header;