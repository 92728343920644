import React from 'react';
import './App.css';
import { useTranslation } from 'react-i18next';

import Header from './Components/Header';
import Slide from '../src/images/slide.jpeg';
import Banner from './Components/Banner';
import Server from '../src/images/server.jpeg';
import Sect1 from '../src/images/sec1.png';
import Sect2 from '../src/images/sec2.png';
import Sect3 from '../src/images/sec3.png';
import Sect4 from '../src/images/sec4.png';
import Cisco from '../src/images/cisco.png'
import Microsoft from '../src/images/microsoft.png'
import Specialist from '../src/images/specialist.png'
import Sse from '../src/images/sse.png'
import Sts from '../src/images/sts.png'





function App () {
  const { t, i18n } = useTranslation();
    return (  
        <div className='main-header'>
             <Header />
                <div className='bread_section'>
                    <Banner/>
                    <div className='focus_section'>
                        <div className='focus_title'>
                          <h3>{t('Services.2')}</h3>
                          <p>{t('Services.3')}</p>
                          <span>{t('Services.4')}</span>

                        </div>

                        <div className='focus_illustration'>
                          <img src={Server} />
                          <p>{t('Services.5')}</p>
                          <h3 className='title'>{t('Services.6')}</h3>

                            <div className='metier_section'>
                                <div className='headline'></div>

                                 <div className='validate_box'>

                                        <div className='validate_sec'>
                                          <img src={Sect1} width="60" height="60" />
                                          <h3>{t('Services.7')}</h3>
                                        </div>

                                        <div className='validate_sec'>
                                          <img src={Sect2} width="60" height="60" />
                                          <h3>{t('Services.8')}</h3>
                                        </div>

                                        <div className='validate_sec'>
                                          <img src={Sect3} width="60" height="60" />
                                          <h3>{t('Services.9')}</h3>
                                        </div>

                                        <div className='validate_sec'>
                                          <img src={Sect4} width="60" height="60" />
                                          <h3>{t('Services.10')}</h3>
                                        </div>
                                </div>

                            </div>

                        </div>
                    </div>


                <div className='certification_section'>
                   <h3 className='certif_title'>{t('Services.11')}</h3>

                   <div className='box_certif'>
                        <div className='box'>
                          <img src={Cisco} />
                          <p>{t('Services.12')}</p>
                        </div>

                        <div className='headline'></div>

                        <div className='box'>
                          <img src={Microsoft} />
                          <p>{t('Services.12')}</p>
                        </div>

                        <div className='headline'></div>

                        <div className='box'>
                          <img src={Specialist} />
                          <p>{t('Services.12')}</p>
                        </div>

                        <div className='headline'></div>

                        <div className='box'>
                          <img src={Sse} />
                          <p>{t('Services.12')}</p>
                        </div>

                        <div className='headline'></div>

                        <div className='box'>
                          <img src={Sts} />
                          <p>{t('Services.12')}</p>
                        </div>
                   </div>
                  
                </div>

                </div>

    
         </div>
         );
      
  
}
 
export default App;
