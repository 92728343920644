import React from "react";
import '../App.css';
import Banner from "../Components/Banner";
import Header from "../Components/Header";


class Experiences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "interv_title": "Domaines d’intervention",
            "interv_desc": "Dans le cadre de ses missions de formation et de transfert de compétences, mais aussi d’audit et de déploiement, Tandem Systems intervient dans de nombreux secteurs d'activité :",
            "interv_exemple": "Plus concrètement... quelques Exemples",
            "exemp1": "Protection des serveurs et postes clients dans un environnement multi-sites",
            "exemp2": "Mise en œuvre d’une solution d’archivage pour 1500 utilisateurs",
            "exemp3": "Plantus nec filis par la ventus dominisuc enviram meris.",
            "exemp4": "Plantus nec filis par la ventus dominisuc enviram meris.",
            "protect_title":"Protection des serveurs et postes clients dans un environnement multi-sites",
            "context_title": "Contexte & Enjeux",
            "context_desc": "Client multi-sites (environ 100) souhaitant protéger des serveurs et postes clients contre les menaces de sécurité informatique (Office National des Pêches du Maroc)",
            "mission_title": "Mission",
            "mission_desc": "Mise en place d’un point central de déploiement et de gestion de l’application de sécurité «Symantec Endpoint Protection» sur tous les postes utilisateurs. Contrôle centralisé pour l’ensemble des sites, avec une possibilité de contrôle local pour chaque administrateur sur site. Protection globale de l’accès réseau de chaque site vis-à-vis des utilisateurs externes avec «Symantec Net Access Control».",
            "result_desc": "Sécurisation globale des sites avec une gestion centralisée facilitée et beaucoup plus efficace."
        }
    }
    state = {  }
    render() { 
        return ( 
            <div className="main-header">
                <Header/>
                 <div className="bread_section">
                    <Banner/>
                    <div className="interv_section">
                            <div className="title_int">
                                <h3>{this.state.interv_title}</h3>
                                <p>{this.state.interv_desc}</p>
                            </div>
                            <div className="client_type">
                                <h6>Banque <br /> <br />Telecom <br /> <br /> SSII <br /> <br /> Grande Distribution Alimentaire <br /> <br /> Industrie Pharmaceutique <br /> <br /> Industrie Pétrolière  <br />  <br />Secteur Public - Ministères, instances territoriales</h6>
                            </div>
                    </div>

                             <div className="exempl_cron">
                                <h3>{this.state.interv_exemple}</h3>
                                    <div className="column_box"> 
                                        <div className="box_exemple">
                                             <h6>
                                                {this.state.exemp1}
                                             </h6>
                                        </div>

                                        <div className="box_exemple">
                                             <h6>
                                                {this.state.exemp2}
                                             </h6>
                                        </div>

                                        <div className="box_exemple">
                                             <h6>
                                                {this.state.exemp3}
                                             </h6>
                                        </div>

                                        <div className="box_exemple">
                                             <h6>
                                                {this.state.exemp4}
                                             </h6>
                                        </div>
                                    </div>

                             </div>
                    
                    <div className="protect_server">
                        <h3>
                            {this.state.protect_title}
                        </h3>

                        <div className='headline-e'></div>
                        <div className="contex_sect">
                            <h3>
                                {this.state.context_title}
                            </h3>
                            <p className="first">
                                {this.state.context_desc}
                            </p>
                        </div>

                        <div className='headline-e'></div>
                        <div className="contex_sect">
                            <h3>
                                {this.state.mission_title}
                            </h3>
                            <p className="two">
                                {this.state.mission_desc}
                            </p>
                        </div>

                        <div className='headline-e'></div>
                        <div className="contex_sect">
                            <h3>
                               Résultat
                            </h3>
                            <p className="three">
                                {this.state.result_desc}
                            </p>
                        </div>
                        <div className='headline-e'></div>



                    </div>











                 </div>
            </div>
         );
    }
}
 
export default Experiences;