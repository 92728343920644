import React from "react";
import '../App.css';
import Banner from "../Components/Banner";
import Header from "../Components/Header";


class Contact extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return ( 
            <div className="main-header">
            <Header/>
             <div className="bread_section">
                <Banner/>

                <div className="contact_section">
                    <h3>Siège social</h3>
                    <span>68, rue Jean Jaurès<br/> 69100 Villeurbanne<br/> France</span>
                    <div className="email_section">
                        <span>Email:</span>
                        <span>info@tandem-systems.net</span>
                    </div>

                    <div className='headline-e'></div>

                    <div className="formulaire_contact">
                        <h3>Envoyer un message</h3>
                      <form action="mail_handler.php" method="post">
                           <div className="semi-block">
                                <input type="text"  name="surname" placeholder="Prénom *" required/>
                                <input type="text"  name="name" placeholder="Nom" required/>
                           </div>
                           
                           <div className="semi-block">
                                <input type="text"  name="society" placeholder="Société *" required/>
                                <input type="text"  name="adresse" placeholder="Adresse *" required/>
                           </div>

                           <div className="semi-block"> 
                                <input type="text"  name="codepostal" placeholder="Code Postal *" required/>
                                <input type="text"  name="ville" placeholder="Ville *" required/>
                           </div>


                            <div className="semi-block">
                                 <input type="text"  name="tel" placeholder="Téléphone *" required/>
                                 <input type="email"  name="emailaddresse" placeholder="Email *" required/>
                            </div>
                           
                           <input type="submit" name="submit" value="Envoyer"/>
                      </form>

                    </div>
                </div>
             </div>
        </div>
         );
    }
}
 
export default Contact;