import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './i18next';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Reflexions from './Pages/Reflexions';
import Partenaires from './Pages/Partenaires';
import Experiences from './Pages/Experiences';
import Contact from './Pages/Contact';
import Certifications from './Pages/Certifications';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={(<div>Chargement...</div>)}>
      <BrowserRouter>
        <Routes>
            <Route path='/' index element={ <App />}/>
            <Route path='/reflexions' index element={ <Reflexions />}/>
            <Route path='/partenaires' index element={ <Partenaires />}/>
            <Route path='/experiences' index element={ <Experiences />}/>
            <Route path='/contact' index element={ <Contact />}/>
            <Route path='/certifications' index element={ <Certifications />}/>
        </Routes>
      </BrowserRouter>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
