import React from "react";
import '../App.css';
import Banner from "../Components/Banner";
import Header from "../Components/Header";
import Sym from "../images/sym.png";
import Sys  from "../images/scs.png";
import Sse  from "../images/sse.png";
import Sts  from "../images/sts.png";
import Cis  from "../images/cis.png";
import Cisco  from "../images/cisco.png";
import Micro  from "../images/micro.png";
import Microsoft  from "../images/microsoft.png";
import Vm  from "../images/vm.png";




class Certifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "certif_title": "Nos certifications sont vos garanties",
            "symantec_title":"Cette certification Symantec est réservée aux formateurs agréés par l’éditeur, tant pour des formations publiques que des missions sur site",
            "symantec_sse":"Cette certification Symantec est permet de valider l’approche commerciale",
            "symantect_sts":"Cette certification Symantec est réservée aux ingénieurs et techniciens",
            "cisco_desc":"Cette certification Cisco est réservée aux structures spécialisées dans le conseil et la vente des solutions PME",
            "microsoft_title":"Cette certification Microsoft est réservée aux ingénieurs et techniciens",
            "vmware_title":"Certification en cours - Technologies de Virtualisatio",
            "accre_title":"Accréditation",
            "symantec_cons":"Symantec Consultant logo ?",
            "symantect_cons_desc":"texte évoquant la certification, le partenaire, le type de collaboration et les avantages clients. texte évoquant la certification, le partenaire, le type de collaboration et les avantages clients. texte évoquant.",
            "training_part_title": "Training Partner logo ?",
            "training_part_desc": "texte évoquant la certification, le partenaire, le type de collaboration et les avantages clients. texte évoquant la certification, le partenaire, le type de collaboration et les avantages clients. texte évoquant.",
            "sujet_inter_title": "Sujets d’intervention",
            "sujet_inter_desc": "Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir, Textes à venir,"
        }
    }
    state = {  }
    render() { 
        return ( 
            <div className="main-header">
               <Header/>
             <div className="bread_section">
                <Banner/>
                <div className="certif_section"> 
                   <h3>
                    {this.state.certif_title}
                   </h3>

                   <div className="box_certification">
                     <img src={Sym} alt="" width="180" height="auto" />
                        <div className='headline-bold'></div>
                        <div className='headline-e'></div>
                        <div className="box_certif_content">
                            <img src={Sys} alt="certif_img" width="80" height="auto"/>
                            <p>
                                {this.state.symantec_title}
                            </p>
                        </div>
                        <div className='headline-e'></div>

                        <div className="box_certif_content">
                            <img src={Sse} alt="certif_img" width="80" height="auto"/>
                            <p>
                                {this.state.symantec_sse}
                            </p>
                        </div>
                        <div className='headline-e'></div>

                        <div className="box_certif_content">
                            <img src={Sts} alt="certif_img" width="80" height="auto"/>
                            <p>
                                {this.state.symantect_sts}
                            </p>
                        </div>
                        <div className='headline-e'></div>
                   </div>


                   <div className="box_certification">
                     <img src={Cis} alt="" width="180" height="auto" />
                        <div className='headline-bold'></div>
                        <div className='headline-e'></div>
                        <div className="box_certif_content">
                            <img src={Cisco} alt="certif_img" width="80" height="auto"/>
                            <p>
                                {this.state.cisco_desc}
                            </p>
                        </div>   
                        <div className='headline-e'></div>
                   </div>



                   <div className="box_certification">
                     <img src={Micro} alt="" width="180" height="auto" />
                        <div className='headline-bold'></div>
                        <div className='headline-e'></div>
                        <div className="box_certif_content">
                            <img src={Microsoft} alt="certif_img" width="80" height="auto"/>
                            <p>
                                {this.state.cisco_desc}
                            </p>
                        </div>   
                        <div className='headline-e'></div>
                   </div>


                   <div className="box_certification">
                     <img src={Vm} alt="" width="180" height="auto" />
                        <div className='headline-bold'></div>
                        <div className='headline-e'></div>
                        <div className="box_certif_content">
                            <h6></h6>
                            <p>
                                {this.state.vmware_title}
                            </p>
                        </div>   
                        <div className='headline-e'></div>
                   </div>

                   <div className="box_certification">
                     <h3>Accréditation</h3>
                        <div className='headline-bold'></div>
                        <div className='headline-e'></div>
                        <div className="box_certif_content">
                           <h6>Symantec Consultant logo ?</h6>
                            <p>
                                {this.state.symantect_cons_desc}
                            </p>
                        </div>   
                        <div className='headline-e'></div>
                        <div className="box_certif_content">
                           <h6>Training Partner logo ?</h6>
                            <p>
                                {this.state.training_part_desc}
                            </p>
                        </div>  
                        <div className='headline-e'></div>
                   </div>


                   <div className="box_certification">
                     <h3>{this.state.sujet_inter_title}</h3>
                      
                        <div className="box_certif_content_final">
                            <p>
                                {this.state.sujet_inter_desc}
                            </p>
                        </div>   
                   </div>

                
                
                </div>
             </div>
          </div>
         );
    }
}
 
export default Certifications;